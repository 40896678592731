import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _36d0345c = () => interopDefault(import('../pages/comparison/index.vue' /* webpackChunkName: "pages/comparison/index" */))
const _e6e94320 = () => interopDefault(import('../pages/dispo/index.vue' /* webpackChunkName: "pages/dispo/index" */))
const _25198264 = () => interopDefault(import('../pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _631552a5 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _9d3d724c = () => interopDefault(import('../pages/help_and_shortcuts/index.vue' /* webpackChunkName: "pages/help_and_shortcuts/index" */))
const _51a64809 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6b45deb8 = () => interopDefault(import('../pages/manage/index.vue' /* webpackChunkName: "pages/manage/index" */))
const _597a8fdf = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _40b172d6 = () => interopDefault(import('../pages/messages/index/inbox.vue' /* webpackChunkName: "pages/messages/index/inbox" */))
const _2861ae78 = () => interopDefault(import('../pages/messages/index/sent.vue' /* webpackChunkName: "pages/messages/index/sent" */))
const _47c3f524 = () => interopDefault(import('../pages/notes/index.vue' /* webpackChunkName: "pages/notes/index" */))
const _01a222ea = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _51a6058d = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _1709e0f6 = () => interopDefault(import('../pages/statistics/index.vue' /* webpackChunkName: "pages/statistics/index" */))
const _07e559be = () => interopDefault(import('../pages/styleguide/index.vue' /* webpackChunkName: "pages/styleguide/index" */))
const _53d881fc = () => interopDefault(import('../pages/system/index.vue' /* webpackChunkName: "pages/system/index" */))
const _78178ca4 = () => interopDefault(import('../pages/tasks.vue' /* webpackChunkName: "pages/tasks" */))
const _6582f243 = () => interopDefault(import('../pages/tasks/_task.vue' /* webpackChunkName: "pages/tasks/_task" */))
const _a2d9f0aa = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _0d01cc62 = () => interopDefault(import('../pages/dispo/projects/index.vue' /* webpackChunkName: "pages/dispo/projects/index" */))
const _7e194d96 = () => interopDefault(import('../pages/dispo/rooms.vue' /* webpackChunkName: "pages/dispo/rooms" */))
const _bca63f7e = () => interopDefault(import('../pages/dispo/rooms/_id.vue' /* webpackChunkName: "pages/dispo/rooms/_id" */))
const _23a471f5 = () => interopDefault(import('../pages/dispo/statistics.vue' /* webpackChunkName: "pages/dispo/statistics" */))
const _7cb6634f = () => interopDefault(import('../pages/dispo/tasks-list.vue' /* webpackChunkName: "pages/dispo/tasks-list" */))
const _290d9f24 = () => interopDefault(import('../pages/dispo/tasks-list/_task.vue' /* webpackChunkName: "pages/dispo/tasks-list/_task" */))
const _09c5dfd3 = () => interopDefault(import('../pages/dispo/timesheet/index.vue' /* webpackChunkName: "pages/dispo/timesheet/index" */))
const _5c158eb4 = () => interopDefault(import('../pages/dispo/users.vue' /* webpackChunkName: "pages/dispo/users" */))
const _eb93dd04 = () => interopDefault(import('../pages/dispo/users/task.vue' /* webpackChunkName: "pages/dispo/users/task" */))
const _0dd7ae13 = () => interopDefault(import('../pages/dispo/users/task/_task.vue' /* webpackChunkName: "pages/dispo/users/task/_task" */))
const _ff06795e = () => interopDefault(import('../pages/dispo/users/_id.vue' /* webpackChunkName: "pages/dispo/users/_id" */))
const _3dc4fd26 = () => interopDefault(import('../pages/manage/agencies.vue' /* webpackChunkName: "pages/manage/agencies" */))
const _2684dfd6 = () => interopDefault(import('../pages/manage/brands.vue' /* webpackChunkName: "pages/manage/brands" */))
const _2d3ddd1e = () => interopDefault(import('../pages/manage/clients/index.vue' /* webpackChunkName: "pages/manage/clients/index" */))
const _b54111c0 = () => interopDefault(import('../pages/manage/contacts/index.vue' /* webpackChunkName: "pages/manage/contacts/index" */))
const _c2895c84 = () => interopDefault(import('../pages/system/feedbacks.vue' /* webpackChunkName: "pages/system/feedbacks" */))
const _0ee88fb4 = () => interopDefault(import('../pages/system/permissions.vue' /* webpackChunkName: "pages/system/permissions" */))
const _502e28a4 = () => interopDefault(import('../pages/system/presets.vue' /* webpackChunkName: "pages/system/presets" */))
const _72f1a1a3 = () => interopDefault(import('../pages/system/settings.vue' /* webpackChunkName: "pages/system/settings" */))
const _24d84ca6 = () => interopDefault(import('../pages/system/system-log.vue' /* webpackChunkName: "pages/system/system-log" */))
const _bbcabace = () => interopDefault(import('../pages/system/tags.vue' /* webpackChunkName: "pages/system/tags" */))
const _4cd60f85 = () => interopDefault(import('../pages/user_management/my_account/index.vue' /* webpackChunkName: "pages/user_management/my_account/index" */))
const _526b45c5 = () => interopDefault(import('../pages/users/profile/index.vue' /* webpackChunkName: "pages/users/profile/index" */))
const _84fbe848 = () => interopDefault(import('../pages/manage/clients/_id/index.vue' /* webpackChunkName: "pages/manage/clients/_id/index" */))
const _392ffbea = () => interopDefault(import('../pages/manage/contacts/_id/index.vue' /* webpackChunkName: "pages/manage/contacts/_id/index" */))
const _72f1f630 = () => interopDefault(import('../pages/users/profile/_id/index.vue' /* webpackChunkName: "pages/users/profile/_id/index" */))
const _b8d443e2 = () => interopDefault(import('../pages/notes/_id/index.vue' /* webpackChunkName: "pages/notes/_id/index" */))
const _22220735 = () => interopDefault(import('../pages/projects/_id.vue' /* webpackChunkName: "pages/projects/_id" */))
const _9f2b822a = () => interopDefault(import('../pages/projects/_id/archive/index.vue' /* webpackChunkName: "pages/projects/_id/archive/index" */))
const _5fdb1f2a = () => interopDefault(import('../pages/projects/_id/details/index.vue' /* webpackChunkName: "pages/projects/_id/details/index" */))
const _4b2db276 = () => interopDefault(import('../pages/projects/_id/preproduction/index.vue' /* webpackChunkName: "pages/projects/_id/preproduction/index" */))
const _151233c4 = () => interopDefault(import('../pages/projects/_id/work/index.vue' /* webpackChunkName: "pages/projects/_id/work/index" */))
const _569ef150 = () => interopDefault(import('../pages/projects/_id/details/bids/index.vue' /* webpackChunkName: "pages/projects/_id/details/bids/index" */))
const _06b0d8a3 = () => interopDefault(import('../pages/projects/_id/details/client_contacts/index.vue' /* webpackChunkName: "pages/projects/_id/details/client_contacts/index" */))
const _6b2144b1 = () => interopDefault(import('../pages/projects/_id/details/general_information/index.vue' /* webpackChunkName: "pages/projects/_id/details/general_information/index" */))
const _88fab906 = () => interopDefault(import('../pages/projects/_id/details/output_settings/index.vue' /* webpackChunkName: "pages/projects/_id/details/output_settings/index" */))
const _2309dc90 = () => interopDefault(import('../pages/projects/_id/details/presets/index.vue' /* webpackChunkName: "pages/projects/_id/details/presets/index" */))
const _a6fe5a0a = () => interopDefault(import('../pages/projects/_id/details/project_folders/index.vue' /* webpackChunkName: "pages/projects/_id/details/project_folders/index" */))
const _10b08e6a = () => interopDefault(import('../pages/projects/_id/details/sum_details/index.vue' /* webpackChunkName: "pages/projects/_id/details/sum_details/index" */))
const _49984ada = () => interopDefault(import('../pages/projects/_id/details/tags/index.vue' /* webpackChunkName: "pages/projects/_id/details/tags/index" */))
const _251f4f31 = () => interopDefault(import('../pages/projects/_id/details/team_members/index.vue' /* webpackChunkName: "pages/projects/_id/details/team_members/index" */))
const _3ab6f5ee = () => interopDefault(import('../pages/projects/_id/details/time_summary/index.vue' /* webpackChunkName: "pages/projects/_id/details/time_summary/index" */))
const _c3223948 = () => interopDefault(import('../pages/projects/_id/details/timing_and_milestones/index.vue' /* webpackChunkName: "pages/projects/_id/details/timing_and_milestones/index" */))
const _7d9e2336 = () => interopDefault(import('../pages/projects/_id/preproduction/bids/index.vue' /* webpackChunkName: "pages/projects/_id/preproduction/bids/index" */))
const _1ac74a0b = () => interopDefault(import('../pages/projects/_id/preproduction/general_information/index.vue' /* webpackChunkName: "pages/projects/_id/preproduction/general_information/index" */))
const _c4feecca = () => interopDefault(import('../pages/projects/_id/preproduction/resources/index.vue' /* webpackChunkName: "pages/projects/_id/preproduction/resources/index" */))
const _165ebb81 = () => interopDefault(import('../pages/projects/_id/preproduction/tasks.vue' /* webpackChunkName: "pages/projects/_id/preproduction/tasks" */))
const _2bd93b17 = () => interopDefault(import('../pages/projects/_id/preproduction/team_members/index.vue' /* webpackChunkName: "pages/projects/_id/preproduction/team_members/index" */))
const _2d597560 = () => interopDefault(import('../pages/projects/_id/work/broadcast/index.vue' /* webpackChunkName: "pages/projects/_id/work/broadcast/index" */))
const _555d5a86 = () => interopDefault(import('../pages/projects/_id/work/dailies/index.vue' /* webpackChunkName: "pages/projects/_id/work/dailies/index" */))
const _7d5bd873 = () => interopDefault(import('../pages/projects/_id/work/dailies/index/_daily.vue' /* webpackChunkName: "pages/projects/_id/work/dailies/index/_daily" */))
const _82276c5e = () => interopDefault(import('../pages/projects/_id/work/dailies/index/_daily/task.vue' /* webpackChunkName: "pages/projects/_id/work/dailies/index/_daily/task" */))
const _34adc7b4 = () => interopDefault(import('../pages/projects/_id/work/dailies/index/_daily/task/_task.vue' /* webpackChunkName: "pages/projects/_id/work/dailies/index/_daily/task/_task" */))
const _712dadb4 = () => interopDefault(import('../pages/projects/_id/work/resources/index.vue' /* webpackChunkName: "pages/projects/_id/work/resources/index" */))
const _9fda12f6 = () => interopDefault(import('../pages/projects/_id/work/shots.vue' /* webpackChunkName: "pages/projects/_id/work/shots" */))
const _6fd35662 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot" */))
const _ff51d5d4 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/audio.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/audio" */))
const _c9e44584 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/audio/_submit.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/audio/_submit" */))
const _e376e834 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/audio/_submit/task.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/audio/_submit/task" */))
const _f5aa8b0a = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/audio/_submit/task/_task.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/audio/_submit/task/_task" */))
const _7254ec54 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/cg.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/cg" */))
const _bc50d308 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/cg/_submit.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/cg/_submit" */))
const _7772e968 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/cg/_submit/task.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/cg/_submit/task" */))
const _576d2306 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/cg/_submit/task/_task.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/cg/_submit/task/_task" */))
const _4309cb7f = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/comp.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/comp" */))
const _02bc1aa7 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/comp/_submit.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/comp/_submit" */))
const _e3bd6fc6 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/comp/_submit/task.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/comp/_submit/task" */))
const _bdfd9d1c = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/comp/_submit/task/_task.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/comp/_submit/task/_task" */))
const _40c7d81b = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/image.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/image" */))
const _1d3c697a = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/image/_submit.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/image/_submit" */))
const _5dbd9901 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/image/_submit/task.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/image/_submit/task" */))
const _5b2e4756 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/image/_submit/task/_task.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/image/_submit/task/_task" */))
const _fe194e64 = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/tasks.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/tasks" */))
const _b5938d3a = () => interopDefault(import('../pages/projects/_id/work/shots/_shot/tasks/_task.vue' /* webpackChunkName: "pages/projects/_id/work/shots/_shot/tasks/_task" */))
const _20543460 = () => interopDefault(import('../pages/projects/_id/work/summarize/index.vue' /* webpackChunkName: "pages/projects/_id/work/summarize/index" */))
const _316c7ada = () => interopDefault(import('../pages/projects/_id/work/tasks.vue' /* webpackChunkName: "pages/projects/_id/work/tasks" */))
const _6598a26f = () => interopDefault(import('../pages/projects/_id/work/tasks/_task.vue' /* webpackChunkName: "pages/projects/_id/work/tasks/_task" */))
const _10576fc6 = () => interopDefault(import('../pages/users/_user/index.vue' /* webpackChunkName: "pages/users/_user/index" */))
const _f002b61c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _023f169c = () => interopDefault(import('../pages/index/task.vue' /* webpackChunkName: "pages/index/task" */))
const _baa04b72 = () => interopDefault(import('../pages/index/task/_task.vue' /* webpackChunkName: "pages/index/task/_task" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/comparison",
    component: _36d0345c,
    name: "comparison"
  }, {
    path: "/dispo",
    component: _e6e94320,
    name: "dispo"
  }, {
    path: "/downloads",
    component: _25198264,
    name: "downloads"
  }, {
    path: "/forgot-password",
    component: _631552a5,
    name: "forgot-password"
  }, {
    path: "/help_and_shortcuts",
    component: _9d3d724c,
    name: "help_and_shortcuts"
  }, {
    path: "/login",
    component: _51a64809,
    name: "login"
  }, {
    path: "/manage",
    component: _6b45deb8,
    name: "manage"
  }, {
    path: "/messages",
    component: _597a8fdf,
    name: "messages",
    children: [{
      path: "inbox",
      component: _40b172d6,
      name: "messages-index-inbox"
    }, {
      path: "sent",
      component: _2861ae78,
      name: "messages-index-sent"
    }]
  }, {
    path: "/notes",
    component: _47c3f524,
    name: "notes"
  }, {
    path: "/notifications",
    component: _01a222ea,
    name: "notifications"
  }, {
    path: "/projects",
    component: _51a6058d,
    name: "projects"
  }, {
    path: "/statistics",
    component: _1709e0f6,
    name: "statistics"
  }, {
    path: "/styleguide",
    component: _07e559be,
    name: "styleguide"
  }, {
    path: "/system",
    component: _53d881fc,
    name: "system"
  }, {
    path: "/tasks",
    component: _78178ca4,
    name: "tasks",
    children: [{
      path: ":task?",
      component: _6582f243,
      name: "tasks-task"
    }]
  }, {
    path: "/users",
    component: _a2d9f0aa,
    name: "users"
  }, {
    path: "/dispo/projects",
    component: _0d01cc62,
    name: "dispo-projects"
  }, {
    path: "/dispo/rooms",
    component: _7e194d96,
    name: "dispo-rooms",
    children: [{
      path: ":id?",
      component: _bca63f7e,
      name: "dispo-rooms-id"
    }]
  }, {
    path: "/dispo/statistics",
    component: _23a471f5,
    name: "dispo-statistics"
  }, {
    path: "/dispo/tasks-list",
    component: _7cb6634f,
    name: "dispo-tasks-list",
    children: [{
      path: ":task?",
      component: _290d9f24,
      name: "dispo-tasks-list-task"
    }]
  }, {
    path: "/dispo/timesheet",
    component: _09c5dfd3,
    name: "dispo-timesheet"
  }, {
    path: "/dispo/users",
    component: _5c158eb4,
    name: "dispo-users",
    children: [{
      path: "task",
      component: _eb93dd04,
      name: "dispo-users-task",
      children: [{
        path: ":task?",
        component: _0dd7ae13,
        name: "dispo-users-task-task"
      }]
    }, {
      path: ":id?",
      component: _ff06795e,
      name: "dispo-users-id"
    }]
  }, {
    path: "/manage/agencies",
    component: _3dc4fd26,
    name: "manage-agencies"
  }, {
    path: "/manage/brands",
    component: _2684dfd6,
    name: "manage-brands"
  }, {
    path: "/manage/clients",
    component: _2d3ddd1e,
    name: "manage-clients"
  }, {
    path: "/manage/contacts",
    component: _b54111c0,
    name: "manage-contacts"
  }, {
    path: "/system/feedbacks",
    component: _c2895c84,
    name: "system-feedbacks"
  }, {
    path: "/system/permissions",
    component: _0ee88fb4,
    name: "system-permissions"
  }, {
    path: "/system/presets",
    component: _502e28a4,
    name: "system-presets"
  }, {
    path: "/system/settings",
    component: _72f1a1a3,
    name: "system-settings"
  }, {
    path: "/system/system-log",
    component: _24d84ca6,
    name: "system-system-log"
  }, {
    path: "/system/tags",
    component: _bbcabace,
    name: "system-tags"
  }, {
    path: "/user_management/my_account",
    component: _4cd60f85,
    name: "user_management-my_account"
  }, {
    path: "/users/profile",
    component: _526b45c5,
    name: "users-profile"
  }, {
    path: "/manage/clients/:id",
    component: _84fbe848,
    name: "manage-clients-id"
  }, {
    path: "/manage/contacts/:id",
    component: _392ffbea,
    name: "manage-contacts-id"
  }, {
    path: "/users/profile/:id",
    component: _72f1f630,
    name: "users-profile-id"
  }, {
    path: "/notes/:id",
    component: _b8d443e2,
    name: "notes-id"
  }, {
    path: "/projects/:id",
    component: _22220735,
    name: "projects-id",
    children: [{
      path: "archive",
      component: _9f2b822a,
      name: "projects-id-archive"
    }, {
      path: "details",
      component: _5fdb1f2a,
      name: "projects-id-details"
    }, {
      path: "preproduction",
      component: _4b2db276,
      name: "projects-id-preproduction"
    }, {
      path: "work",
      component: _151233c4,
      name: "projects-id-work"
    }, {
      path: "details/bids",
      component: _569ef150,
      name: "projects-id-details-bids"
    }, {
      path: "details/client_contacts",
      component: _06b0d8a3,
      name: "projects-id-details-client_contacts"
    }, {
      path: "details/general_information",
      component: _6b2144b1,
      name: "projects-id-details-general_information"
    }, {
      path: "details/output_settings",
      component: _88fab906,
      name: "projects-id-details-output_settings"
    }, {
      path: "details/presets",
      component: _2309dc90,
      name: "projects-id-details-presets"
    }, {
      path: "details/project_folders",
      component: _a6fe5a0a,
      name: "projects-id-details-project_folders"
    }, {
      path: "details/sum_details",
      component: _10b08e6a,
      name: "projects-id-details-sum_details"
    }, {
      path: "details/tags",
      component: _49984ada,
      name: "projects-id-details-tags"
    }, {
      path: "details/team_members",
      component: _251f4f31,
      name: "projects-id-details-team_members"
    }, {
      path: "details/time_summary",
      component: _3ab6f5ee,
      name: "projects-id-details-time_summary"
    }, {
      path: "details/timing_and_milestones",
      component: _c3223948,
      name: "projects-id-details-timing_and_milestones"
    }, {
      path: "preproduction/bids",
      component: _7d9e2336,
      name: "projects-id-preproduction-bids"
    }, {
      path: "preproduction/general_information",
      component: _1ac74a0b,
      name: "projects-id-preproduction-general_information"
    }, {
      path: "preproduction/resources",
      component: _c4feecca,
      name: "projects-id-preproduction-resources"
    }, {
      path: "preproduction/tasks",
      component: _165ebb81,
      name: "projects-id-preproduction-tasks"
    }, {
      path: "preproduction/team_members",
      component: _2bd93b17,
      name: "projects-id-preproduction-team_members"
    }, {
      path: "work/broadcast",
      component: _2d597560,
      name: "projects-id-work-broadcast"
    }, {
      path: "work/dailies",
      component: _555d5a86,
      name: "projects-id-work-dailies",
      children: [{
        path: ":daily?",
        component: _7d5bd873,
        name: "projects-id-work-dailies-index-daily",
        children: [{
          path: "task",
          component: _82276c5e,
          name: "projects-id-work-dailies-index-daily-task",
          children: [{
            path: ":task?",
            component: _34adc7b4,
            name: "projects-id-work-dailies-index-daily-task-task"
          }]
        }]
      }]
    }, {
      path: "work/resources",
      component: _712dadb4,
      name: "projects-id-work-resources"
    }, {
      path: "work/shots",
      component: _9fda12f6,
      name: "projects-id-work-shots",
      children: [{
        path: ":shot?",
        component: _6fd35662,
        name: "projects-id-work-shots-shot",
        children: [{
          path: "audio",
          component: _ff51d5d4,
          name: "projects-id-work-shots-shot-audio",
          children: [{
            path: ":submit?",
            component: _c9e44584,
            name: "projects-id-work-shots-shot-audio-submit",
            children: [{
              path: "task",
              component: _e376e834,
              name: "projects-id-work-shots-shot-audio-submit-task",
              children: [{
                path: ":task?",
                component: _f5aa8b0a,
                name: "projects-id-work-shots-shot-audio-submit-task-task"
              }]
            }]
          }]
        }, {
          path: "cg",
          component: _7254ec54,
          name: "projects-id-work-shots-shot-cg",
          children: [{
            path: ":submit?",
            component: _bc50d308,
            name: "projects-id-work-shots-shot-cg-submit",
            children: [{
              path: "task",
              component: _7772e968,
              name: "projects-id-work-shots-shot-cg-submit-task",
              children: [{
                path: ":task?",
                component: _576d2306,
                name: "projects-id-work-shots-shot-cg-submit-task-task"
              }]
            }]
          }]
        }, {
          path: "comp",
          component: _4309cb7f,
          name: "projects-id-work-shots-shot-comp",
          children: [{
            path: ":submit?",
            component: _02bc1aa7,
            name: "projects-id-work-shots-shot-comp-submit",
            children: [{
              path: "task",
              component: _e3bd6fc6,
              name: "projects-id-work-shots-shot-comp-submit-task",
              children: [{
                path: ":task?",
                component: _bdfd9d1c,
                name: "projects-id-work-shots-shot-comp-submit-task-task"
              }]
            }]
          }]
        }, {
          path: "image",
          component: _40c7d81b,
          name: "projects-id-work-shots-shot-image",
          children: [{
            path: ":submit?",
            component: _1d3c697a,
            name: "projects-id-work-shots-shot-image-submit",
            children: [{
              path: "task",
              component: _5dbd9901,
              name: "projects-id-work-shots-shot-image-submit-task",
              children: [{
                path: ":task?",
                component: _5b2e4756,
                name: "projects-id-work-shots-shot-image-submit-task-task"
              }]
            }]
          }]
        }, {
          path: "tasks",
          component: _fe194e64,
          name: "projects-id-work-shots-shot-tasks",
          children: [{
            path: ":task?",
            component: _b5938d3a,
            name: "projects-id-work-shots-shot-tasks-task"
          }]
        }]
      }]
    }, {
      path: "work/summarize",
      component: _20543460,
      name: "projects-id-work-summarize"
    }, {
      path: "work/tasks",
      component: _316c7ada,
      name: "projects-id-work-tasks",
      children: [{
        path: ":task?",
        component: _6598a26f,
        name: "projects-id-work-tasks-task"
      }]
    }]
  }, {
    path: "/users/:user",
    component: _10576fc6,
    name: "users-user"
  }, {
    path: "/",
    component: _f002b61c,
    name: "index",
    children: [{
      path: "task",
      component: _023f169c,
      name: "index-task",
      children: [{
        path: ":task?",
        component: _baa04b72,
        name: "index-task-task"
      }]
    }]
  }, {
    path: "/me",
    component: _4cd60f85,
    name: "user_management-my_account/me"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
