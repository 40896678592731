<template>
    <v-menu
        ref="headerNotificationsMenu"
        close-on-content-click
        offset-y
        open-on-hover
        open-on-focus
        close-delay="200"
        persistent
    >
        <template #activator="{ on }">
            <v-btn
                icon
                medium
                class="relative ml-2 z-10"
                title="Notifications"
                v-on="on"
                @click="() => { justOpened = true }"
            >
                <i class="las la-bell" />
                <span
                    v-if="processedNotifications.map((n) => n.isRead).includes(false)"
                    class="absolute top-0 right-0 border-3 border-gray-300 dark:border-dark-800 bg-red text-white rounded-full w-4 h-4 flex items-center justify-center"
                />
            </v-btn>
        </template>

        <div
            v-click-outside="() => { if (!justOpened) $refs.headerNotificationsMenu._data.isActive = false; else justOpened = false; }"
            class="v-menu-body"
            style="padding: 12px"
        >
            <div v-for="item of processedNotifications" :key="item.id">
                <a @click="readNotification(item.clickableLink, item.id)" >
                    <div class="flex flex-wrap items-center justify-start border-b last:border-b-0 border-gray-600 pt-2 pt-3 pb-1" style="border-bottom: 1px solid #444; width: 20rem">
                        <div class="flex flex-wrap mb-2" style="width: 100%">
                            <span
                                v-if="!item.isRead"
                                class="bg-red text-white rounded-full w-4 h-4"
                                style="margin-top: 0.25rem; margin-right: 0.25rem;"
                            />
                            <div :class="item.event + '_noti_event'" class="noti_event">{{ item.event }}</div>
                            <div class="noti_event noti_type">{{ item.niceType }}</div>
                            <user-badge is-small color="white" class="flex" style="margin-left: auto; margin-right: 8px" short-name :user="item.created_meta" />
                        </div>
                        <div class="tasks-table" style="width: 100%">
                            <span class="text-xs text-white text-left" :class="item.class" style="display: block;">
                                <b v-if="item.description" v-html="item.description" ></b>
                                <br>
                                <!-- name to flex start date to end -->
                                <div class="flex text-gray-500">
                                    <span>{{ item.project ? item.project.name : ''}}</span>
                                    <div  class="ml-auto">
                                        <date :date="item.created_meta.date" />
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </a>
            </div>

            <div class="flex gap-4 mt-2">
                <v-btn color="tertiary" to="/notifications"><span class="px-1">Show all</span></v-btn>
                <v-btn color="tertiary" class="ml-auto" @click="() => readNotification()">Mark all read</v-btn>
            </div>
        </div>
    </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            justOpened: false,
        }
    },
    computed: {
        ...mapGetters({
            notifications: 'users/me/getNotifications',
            newNotification: 'users/me/getNewNotification',
        }),
        processedNotifications() {

            return this.notifications.map((notification) => {
                const tmp = notification;
                const originalType = tmp.type;
                const type = originalType.replaceAll('App\\Entity\\','').toLowerCase();
                tmp.niceType = originalType.replaceAll('App\\Entity\\','');
                tmp.description = '';
                tmp.class = '';
                if (tmp.prettyLink) {

                    const data = tmp.prettyLink.split('/');
                    if (type === 'submit') {
                        tmp.description = `${data[5]} of Pass: ${data[4]}`
                    } else if (type === 'pass') {
                        tmp.description = `${data[3]} of Shot: ${data[2]}`
                    } else if (type === 'task') {
                        tmp.description = `${data[2]}`;
                        
                        if (tmp.event === 'update' && tmp.data.status) {
                            const to = this.$store.state.config.config.dispo.task_statuses
                                .find((e) => e.id === tmp.data.status[1])
                                .label.toLowerCase()
                                .replaceAll(' ', '-')
                            tmp.class = to;
                            tmp.description += `
                                <br>
                                Set to
                                <span class="color-badge type-badge rounded h-5 text-secondary inline-flex items-center px-1" style="font-weight: normal">
                                    ${to}
                                </span>
                            `;
                        }
                    }
                }

                // type = type.replaceAll('App\\Entity\\','').toLowerCase();
                if (type !== originalType) {

                    let link = '';

                    if (type === 'project') {
                        link = `/projects/${tmp.entityId}`;
                    } else if (type === 'user') {
                        link = `/users/${tmp.entityId}`;
                    } else if (type === 'system') {
                        link = `/system`;
                    } else if (type === 'task') {
                        link = `/projects/${tmp.project.id}/work/tasks/${tmp.entityId}`;
                    } else if (type === 'submit') {
                        if (tmp.extendedData && tmp.extendedData.shotId && tmp.extendedData.passType) {
                            link = `/projects/${tmp.project.id}/work/shots/${tmp.extendedData.shotId}/${tmp.extendedData.passType.toLowerCase()}/${tmp.entityId}/`;
                        } else {
                            link = `/projects/${tmp.project.id}/work/shots`;
                        }
                    } else if (type === 'bid') {
                        link = `/projects/${tmp.project.id}/details/bids/`;
                    } else if (type === 'brodadcast') {
                        link = `/projects/${tmp.project.id}/work/broadcasts/`;
                    } else if (type === 'daily') {
                        link = `/projects/${tmp.project.id}/work/dailies/`;
                    } else if (type === 'milestone') {
                        link = `/projects/${tmp.project.id}/details/timing_and_milestones/`;
                    } else if (type === 'resource') {
                        link = `/projects/${tmp.project.id}/work/resources/`;
                    } else if (type === 'timesheetentry') {
                        link = `/projects/${tmp.project.id}/details/timing_and_milestones/`;
                    } else if (type === 'pass') {
                        if (tmp.extendedData && tmp.extendedData.shotId && tmp.extendedData.passType) {
                            link = `/projects/${tmp.project.id}/work/shots/${tmp.extendedData.shotId}/${tmp.extendedData.passType.toLowerCase()}`;
                        } else {
                            link = `/projects/${tmp.project.id}/work/shots/`;
                        }
                    } else if (type === 'dispoentry') {
                        link = `/dispo/`;
                    } else if (type === 'broadcastdelivery') {
                        link = `/projects/${tmp.project.id}/work/broadcasts/`;
                    } else if (type === 'taskcomment') {
                        link = `/projects/${tmp.project.id}/work/tasks/`;
                    } else if (type === 'shot') {
                        if (tmp.event === 'delete') {
                            link = `/projects/${tmp.project.id}/work/shots/`;
                        } else {
                            link = `/projects/${tmp.project.id}/work/shots/${tmp.entityId}`;
                        }
                    } else if (type === 'sequence') {
                        if (tmp.event === 'delete') {
                            link = `/projects/${tmp.project.id}/work/sequences/`;
                        } else {
                            link = `/projects/${tmp.project.id}/work/sequences/${tmp.entityId}`;
                        }

                    } else if (type === 'projectmember') {
                        if (tmp.project) {
                            link = `/projects/${tmp.project.id}/details/team_members/`;
                        }
                    }
                    tmp.clickableLink = link;
                }
                return tmp;
            });
        },
    },
    methods: {
        async readNotification(link, id) {
            let entity = null;
            if (id) {
                entity = this.notifications.find((e) => e.id === id);
            }
            if ((entity && entity.isRead === false) || !id) {
                await this.$axios.$post(`${process.env.prodAPI}/notifications/read` + (id ? `/${id}` : '' ))
                    .catch((e) => {
                        if (e?.response?.data?.errors) {
                            for (const error of e.response.data.errors) {
                                this.$notify({
                                    title: e.response.data.message,
                                    text: `${error.key}: ${error.message}`,
                                    type: 'error',
                                });
                            }
                        } else {
                            this.$notify({
                                title: 'Unknown error occurred',
                                type: 'error',
                            });
                        }
                    });
            }

            // Update user data
            this.$store.dispatch('users/me/setMe');
            // Visit link if specified
            if (link) {
                this.$router.push(link);
            }
        }
    },
};
</script>
<style>
.noti_event {
    padding: 2px 4px;
    border-radius: 4px;
    margin: 4px 4px 4px 0;
    max-height: 18px;
    font-size: 10px;
}

.noti_type {
    @apply bg-gray-300;
    .dark-mode & {
        @apply bg-dark-800;
    }
}

.create_noti_event {
    @apply bg-blue-300;
    .dark-mode & {
        @apply bg-blue-400;
    }
}


.delete_noti_event {
    @apply bg-red-500/50;

    .dark-mode & {
        @apply bg-red-500;

    }
}


.update_noti_event {
    color: #2D3A4A;
    @apply bg-yellow/50;


    .dark-mode & {
        @apply bg-yellow;

    }
}


</style>
